import './style.css';
// import * as dat from 'lil-gui';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {
  GPUComputationRenderer,
} from 'three/examples/jsm/misc/GPUComputationRenderer.js';
import particlesPositionShader from './shaders/particles/position.glsl';
import particlesVelocityShader from './shaders/particles/velocity.glsl';
import {
  DepthOfFieldEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
  VignetteEffect,
  GlitchEffect, NoiseEffect,BlendFunction,
  ChromaticAberrationEffect,
  ScanlineEffect,
} from 'postprocessing';
import Stats from 'stats.js'
var stats = new Stats();
stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
document.getElementById('instruction').appendChild( stats.dom );
// import {FilmPass} from 'three/examples/jsm/postprocessing/FilmPass.js';

class Sizes extends THREE.EventDispatcher {
  constructor () {
    super ();
    this.update ();
    window.addEventListener ('resize', () => {
      this.update ();
    });
  }

  update () {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.aspect = this.width / this.height;
    this.pixelRatio = Math.min (window.devicePixelRatio, 2);
    this.dispatchEvent ({type: 'resize', target: this});
  }
}

const event = new Event ('jump');
const timedelay = 2000;

class Mouse3D {
  constructor (camera, sizes) {
    this.camera = camera;
    this.position2 = new THREE.Vector2 ();
    this.position3 = new THREE.Vector3 ();
    this.ray = new THREE.Ray ();
    this.distance = 0;

    // const onPointerMove = event => {
    //   this.position2.x = event.clientX / sizes.width * 2 - 1;
    //   this.position2.y = -(event.clientY / sizes.height) * 2 + 1;
    //   console.log (this, this.position2);
    // };

    const newRandom = () => {
      console.warn ('fclick');
      this.position2.x = Math.random () * 2 - 1;
      this.position2.y = -Math.random () * 2 + 1;
      // console.log (this, this.position2);
      this.update ();
    };

    window.addEventListener ('jump', () => {
      if (Math.random () > 0.45)
        setTimeout (newRandom, Math.random () * timedelay);
    });
    // window.addEventListener('mousemove', onPointerMove)
    // window.addEventListener('touchmove', (event) => {
    //   event.preventDefault()
    //   onPointerMove(event.changedTouches[0])
    // })
  }

  update () {
    const {ray, camera, position2, position3} = this;
    ray.origin.copy (camera.position);
    ray.direction
      .set (position2.x, position2.y, 0.5)
      .unproject (camera)
      .sub (ray.origin)
      .normalize ();

    this.distance =
      ray.origin.length () /
      Math.cos (Math.PI - ray.direction.angleTo (ray.origin));

    position3.copy (ray.direction);
    position3.multiplyScalar (this.distance);
    position3.add (ray.origin);
  }
}

class Particles {
  constructor (renderer, mouse3D, pallete) {
    this.mouse3D = mouse3D;

    this.elapsedTime = 0;
    this.initGPUComputationRenderer (renderer);
    this.initParticles (pallete);
  }

  initGPUComputationRenderer (renderer) {
    const textureWidth = 256*sizes.width/1000; //256
    const textureHeight = 128*sizes.height/1000; //128
    const numParticles = textureWidth * textureHeight;
    const gpuCompute = new GPUComputationRenderer (
      textureWidth,
      textureWidth,
      renderer
    );
    const isSafari =
      !!navigator.userAgent.match (/Safari/i) &&
      !navigator.userAgent.match (/Chrome/i);
    if (isSafari) {
      gpuCompute.setDataType (THREE.HalfFloatType);
    }
    const texturePosition = gpuCompute.createTexture ();
    const textureVelocity = gpuCompute.createTexture ();
    (function fillTextures () {
      const positionArray = texturePosition.image.data;
      const velocityArray = textureVelocity.image.data;

      for (let i = 0; i < numParticles; i++) {
        const i4 = i * 4;
        const r = (0.5 + Math.random () * 0.5) * 50;
        const phi = (Math.random () - 0.5) * Math.PI;
        const theta = Math.random () * Math.PI * 2;
        positionArray[i4 + 0] = r * Math.cos (theta) * Math.cos (phi);
        positionArray[i4 + 1] = r * Math.sin (phi);
        positionArray[i4 + 2] = r * Math.sin (theta) * Math.cos (phi);
        positionArray[i4 + 3] = 4 * Math.random () * Math.random ();
        velocityArray[i4 + 0] = 0;
        velocityArray[i4 + 1] = 0;
        velocityArray[i4 + 2] = 0;
        velocityArray[i4 + 3] = 0;
      }
    }) ();
    const textureDefaultPosition = texturePosition.clone ();
    const positionVariable = gpuCompute.addVariable (
      'texturePosition',
      particlesPositionShader,
      texturePosition
    );
    const velocityVariable = gpuCompute.addVariable (
      'textureVelocity',
      particlesVelocityShader,
      textureVelocity
    );
    gpuCompute.setVariableDependencies (positionVariable, [
      positionVariable,
      velocityVariable,
    ]);
    gpuCompute.setVariableDependencies (velocityVariable, [
      positionVariable,
      velocityVariable,
    ]);

    positionVariable.material.uniforms.uTime = {value: 0.0};
    positionVariable.material.uniforms.uDelta = {value: 0.0};
    positionVariable.material.uniforms.uDieSpeed = {value: 0.00813};
    positionVariable.material.uniforms.uRadius = {value: 0.36};
    positionVariable.material.uniforms.uMouse3d = {value: new THREE.Vector3 ()};
    positionVariable.material.uniforms.uTextureDefaultPosition = {
      value: textureDefaultPosition,
    };
    velocityVariable.material.uniforms.uTime = {value: 0.0};
    velocityVariable.material.uniforms.uDelta = {value: 0.0};
    velocityVariable.material.uniforms.uSpeed = {value: 2.1};
    velocityVariable.material.uniforms.uAttraction = {value: 1};
    velocityVariable.material.uniforms.uCurlSize = {value: 0.02};
    velocityVariable.material.uniforms.uTimeScale = {value: 0.8};
    velocityVariable.material.uniforms.uMouse3d = {value: new THREE.Vector3 ()};

    const error = gpuCompute.init ();
    if (error !== null) {
      console.error (error);
    }

    this.numParticles = numParticles;
    this.textureWidth = textureWidth;
    this.gpuCompute = gpuCompute;
    this.positionVariable = positionVariable;
    this.velocityVariable = velocityVariable;
  }

  initParticles (pallete) {
    const {textureWidth, numParticles} = this;
    const particlesGeometry = (() => {
      const geom = new THREE.OctahedronGeometry (0.5);
      const refs = new Float32Array (numParticles * 2);
      for (let i = 0; i < numParticles; i++) {
        const i2 = i * 2;
        refs[i2 + 0] = i % textureWidth / (textureWidth - 1);
        refs[i2 + 1] = ~~(i / textureWidth) / (textureWidth - 1);
      }
      geom.setAttribute (
        'aReference',
        new THREE.InstancedBufferAttribute (refs, 2)
      );
      return geom;
    }) ();
    const particlesMaterial = this.injectParticleMotion (
      new THREE.MeshStandardMaterial ({
        metalness: 0.6,
        roughness: 0.8,
        flatShading: true,
      }),
      true
    );
    const particles = new THREE.InstancedMesh (
      particlesGeometry,
      particlesMaterial,
      numParticles
    );
    particles.castShadow = true;
    particles.receiveShadow = true;
    particles.customDepthMaterial = this.injectParticleMotion (
      new THREE.MeshDepthMaterial ({
        depthPacking: THREE.RGBADepthPacking,
      })
    );

    const instanceColors = pallete.map (hex =>
      new THREE.Color (hex).convertSRGBToLinear ()
    );
    const dummy = new THREE.Object3D ();
    for (let i = 0; i < numParticles; i++) {
      particles.setMatrixAt (i, dummy.matrix);
      particles.setColorAt (
        i,
        instanceColors[~~(Math.pow (Math.random (), 2) * instanceColors.length)]
      );
    }

    this.node = particles;
  }

  injectParticleMotion (material, transformNormal = false) {
    if (this.particlesUniforms == null) {
      this.particlesUniforms = {
        uTexturePosition: {value: null},
        uTextureVelocity: {value: null},
      };
    }

    material.onBeforeCompile = shader => {
      shader.uniforms.uTexturePosition = this.particlesUniforms.uTexturePosition;
      shader.uniforms.uTextureVelocity = this.particlesUniforms.uTextureVelocity;
      shader.vertexShader = shader.vertexShader.replace (
        '#include <common>',
        `
          #include <common>

          uniform sampler2D uTexturePosition;
          uniform sampler2D uTextureVelocity;

          attribute vec2 aReference;

          mat3 getRotation(vec3 velocity) {
            velocity = normalize(velocity);
            velocity.z *= -2.;

            float xz = length( velocity.xz );
            float xyz = 1.;
            float x = sqrt( 1. - velocity.y * velocity.y );

            float cosry = velocity.x / xz;
            float sinry = velocity.z / xz;

            float cosrz = x / xyz;
            float sinrz = velocity.y / xyz;

            mat3 maty =  mat3( cosry, 0, -sinry, 0    , 1, 0     , sinry, 0, cosry );
            mat3 matz =  mat3( cosrz , sinrz, 0, -sinrz, cosrz, 0, 0     , 0    , 1 );

            return maty * matz;
          }
        `
      );

      if (transformNormal) {
        shader.vertexShader = shader.vertexShader.replace (
          '#include <beginnormal_vertex>',
          `
            #include <beginnormal_vertex>

            vec4 velocityInfo = texture2D(uTextureVelocity, aReference);
            mat3 particleRotation = getRotation(velocityInfo.xyz);
            vec3 particleScale = vec3(
              min(4.0, 3.0 * length(velocityInfo.xyz)) + 2.0,
              1.0,
              1.0
            );

            objectNormal = normalize(particleRotation * objectNormal / particleScale);
          `
        );
        shader.vertexShader = shader.vertexShader.replace (
          '#include <begin_vertex>',
          `
            #include <begin_vertex>

            vec4 positionInfo = texture2D(uTexturePosition, aReference);

            transformed *= positionInfo.w * particleScale;
            transformed = particleRotation * transformed;
            transformed += positionInfo.xyz;
          `
        );
      } else {
        shader.vertexShader = shader.vertexShader.replace (
          '#include <begin_vertex>',
          `
            #include <begin_vertex>

            vec4 positionInfo = texture2D(uTexturePosition, aReference);
            vec4 velocityInfo = texture2D(uTextureVelocity, aReference);
            mat3 particleRotation = getRotation(velocityInfo.xyz);
            vec3 particleScale = vec3(
              min(4.0, 3.0 * length(velocityInfo.xyz)) + 2.0,
              1.0,
              1.0
            );

            transformed *= positionInfo.w * particleScale;
            transformed = particleRotation * transformed;
            transformed += positionInfo.xyz;
          `
        );
      }
    };
    return material;
  }

  update (deltaTime) {
    const {
      positionVariable,
      velocityVariable,
      gpuCompute,
      particlesUniforms,
    } = this;

    this.elapsedTime += deltaTime / 4;

    const deltaRatio = 60 * deltaTime;
    positionVariable.material.uniforms.uTime.value = this.elapsedTime;
    positionVariable.material.uniforms.uDelta.value = deltaRatio;
    positionVariable.material.uniforms.uMouse3d.value.copy (
      this.mouse3D.position3
    );
    velocityVariable.material.uniforms.uTime.value = this.elapsedTime;
    velocityVariable.material.uniforms.uDelta.value = deltaRatio;
    velocityVariable.material.uniforms.uMouse3d.value.copy (
      this.mouse3D.position3
    );

    gpuCompute.compute ();

    particlesUniforms.uTexturePosition.value = gpuCompute.getCurrentRenderTarget (
      positionVariable
    ).texture;
    particlesUniforms.uTextureVelocity.value = gpuCompute.getCurrentRenderTarget (
      velocityVariable
    ).texture;
  }
}

// Canvas
const canvas = document.querySelector ('canvas.webgl');

// Sizes
const sizes = new Sizes ();

// Scene
const scene = new THREE.Scene ();

// Camera
const camera = new THREE.PerspectiveCamera (45, sizes.aspect, 10, 3000);
camera.position.set (-300, 80, -300).normalize ().multiplyScalar (320);
scene.add (camera);

// Controls
const controls = new OrbitControls (camera, canvas);
controls.target.y = 60;
controls.maxDistance = 400;
controls.minPolarAngle = 0.3;
controls.maxPolarAngle = Math.PI / 2 - 0.1;
controls.enablePan = false;
controls.enableDamping = true;
controls.autoRotate = true;

// Renderer
const renderer = new THREE.WebGLRenderer ({
  canvas: canvas,
  powerPreference: 'high-performance',
  antialias: false,
  stencil: false,
  depth: false,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding; // need for encoding
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.5;
renderer.setSize (sizes.width, sizes.height);
renderer.setPixelRatio (sizes.pixelRatio);

// Composer
const composer = new EffectComposer (renderer, {
  multisampling: renderer.capabilities.isWebGL2 && sizes.pixelRatio === 1
    ? 2
    : undefined,
});

const depthOfFieldEffect = new DepthOfFieldEffect(camera, {
  focusDistance: 0.0,
  focalLength: .6048,
  bokehScale: 0.60,
  height: 480,
})



const renderPass = new RenderPass( scene, camera );
composer.addPass( renderPass );



const chromaticAberrationEffect = new ChromaticAberrationEffect();

const glitchEffect = new GlitchEffect({
  chromaticAberrationOffset: chromaticAberrationEffect.offset
});
const noiseEffect = new NoiseEffect({
  blendFunction: BlendFunction.COLOR_DODGE
});


// noiseEffect.blendMode.opacity.value = 0.1;
const scanlineEffect = new ScanlineEffect({
  blendFunction: BlendFunction.MULTIPLY,
  // opacity:.325,
  density:0.75//.0001,

});

scanlineEffect.blendMode.opacity.value = 0.15;
noiseEffect.blendMode.opacity.value = 0.04;

// console.log(scanlineEffect,noiseEffect)

// const glitchPass = new EffectPass(camera, glitchEffect);//noiseEffect
// const chromaticAberrationPass = new EffectPass(camera, chromaticAberrationEffect);
// composer.addPass(glitchPass);
// const depthOfFieldPass = new EffectPass(camera, depthOfFieldEffect)
// composer.addPass(depthOfFieldPass);

composer.addPass (new EffectPass (camera, scanlineEffect,noiseEffect,depthOfFieldEffect));// composer.addPass(chromaticAberrationPass);

composer.addPass (new EffectPass (camera, new VignetteEffect ()));



// Floor
const plane = new THREE.Mesh (
  new THREE.PlaneGeometry (3000, 3000),
  new THREE.MeshStandardMaterial ({
    roughness: 1,
    metalness: 0.7,
  })
);
plane.rotation.x = -Math.PI / 2;
plane.position.y = -40;
plane.receiveShadow = true;
scene.add (plane);

// Lights
const directionalLight = new THREE.DirectionalLight ('#ffffff', 4);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set (2048, 2048);
directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 800;
directionalLight.shadow.camera.left = -250;
directionalLight.shadow.camera.right = 250;
directionalLight.shadow.camera.top = 250;
directionalLight.shadow.camera.bottom = -250;
directionalLight.position.set (-3, 2, -0.35).normalize ().multiplyScalar (200);
scene.add (directionalLight);

const directionalLight2 = new THREE.DirectionalLight ('#ffffff', 4);
directionalLight2.castShadow = true;
directionalLight2.shadow.mapSize.set (2048, 2048);
directionalLight2.shadow.camera.near = 1;
directionalLight2.shadow.camera.far = 800;
directionalLight2.shadow.camera.left = -250;
directionalLight2.shadow.camera.right = 250;
directionalLight2.shadow.camera.top = 250;
directionalLight2.shadow.camera.bottom = -250;
directionalLight2.position.set (3, 2, 0.35).normalize ().multiplyScalar (200);
scene.add (directionalLight2);

// scene.add(new THREE.CameraHelper(directionalLight.shadow.camera))
const ambientLight = new THREE.AmbientLight ('#ffffff', 0.915);
scene.add (ambientLight);

// Background colors
const bgColorLinear = new THREE.Color ('#111').convertSRGBToLinear ();
plane.material.color = bgColorLinear;
renderer.setClearColor (bgColorLinear);
scene.fog = new THREE.Fog (bgColorLinear, 500, 800);

//////////////

const dragon = [
  '#EF2006',
  '#350000',
  '#A11104',
  '#ED5910',
  '#F1B52E',
  '#7B5614',
  '#F7F1AC',
];

const blues = [
  '#f7fbff',
  '#f6faff',
  '#f5fafe',
  '#f5f9fe',
  '#f4f9fe',
  '#f3f8fe',
  '#f2f8fd',
  '#f2f7fd',
  '#f1f7fd',
  '#f0f6fd',
  '#eff6fc',
  '#eef5fc',
  '#eef5fc',
  '#edf4fc',
  '#ecf4fb',
  '#ebf3fb',
  '#eaf3fb',
  '#eaf2fb',
  '#e9f2fa',
  '#e8f1fa',
  '#e7f1fa',
  '#e7f0fa',
  '#e6f0f9',
  '#e5eff9',
  '#e4eff9',
  '#e3eef9',
  '#e3eef8',
  '#e2edf8',
  '#e1edf8',
  '#e0ecf8',
  '#e0ecf7',
  '#dfebf7',
  '#deebf7',
  '#ddeaf7',
  '#ddeaf6',
  '#dce9f6',
  '#dbe9f6',
  '#dae8f6',
  '#d9e8f5',
  '#d9e7f5',
  '#d8e7f5',
  '#d7e6f5',
  '#d6e6f4',
  '#d6e5f4',
  '#d5e5f4',
  '#d4e4f4',
  '#d3e4f3',
  '#d2e3f3',
  '#d2e3f3',
  '#d1e2f3',
  '#d0e2f2',
  '#cfe1f2',
  '#cee1f2',
  '#cde0f1',
  '#cce0f1',
  '#ccdff1',
  '#cbdff1',
  '#cadef0',
  '#c9def0',
  '#c8ddf0',
  '#c7ddef',
  '#c6dcef',
  '#c5dcef',
  '#c4dbee',
  '#c3dbee',
  '#c2daee',
  '#c1daed',
  '#c0d9ed',
  '#bfd9ec',
  '#bed8ec',
  '#bdd8ec',
  '#bcd7eb',
  '#bbd7eb',
  '#b9d6eb',
  '#b8d5ea',
  '#b7d5ea',
  '#b6d4e9',
  '#b5d4e9',
  '#b4d3e9',
  '#b2d3e8',
  '#b1d2e8',
  '#b0d1e7',
  '#afd1e7',
  '#add0e7',
  '#acd0e6',
  '#abcfe6',
  '#a9cfe5',
  '#a8cee5',
  '#a7cde5',
  '#a5cde4',
  '#a4cce4',
  '#a3cbe3',
  '#a1cbe3',
  '#a0cae3',
  '#9ec9e2',
  '#9dc9e2',
  '#9cc8e1',
  '#9ac7e1',
  '#99c6e1',
  '#97c6e0',
  '#96c5e0',
  '#94c4df',
  '#93c3df',
  '#91c3df',
  '#90c2de',
  '#8ec1de',
  '#8dc0de',
  '#8bc0dd',
  '#8abfdd',
  '#88bedc',
  '#87bddc',
  '#85bcdc',
  '#84bbdb',
  '#82bbdb',
  '#81badb',
  '#7fb9da',
  '#7eb8da',
  '#7cb7d9',
  '#7bb6d9',
  '#79b5d9',
  '#78b5d8',
  '#76b4d8',
  '#75b3d7',
  '#73b2d7',
  '#72b1d7',
  '#70b0d6',
  '#6fafd6',
  '#6daed5',
  '#6caed5',
  '#6badd5',
  '#69acd4',
  '#68abd4',
  '#66aad3',
  '#65a9d3',
  '#63a8d2',
  '#62a7d2',
  '#61a7d1',
  '#5fa6d1',
  '#5ea5d0',
  '#5da4d0',
  '#5ba3d0',
  '#5aa2cf',
  '#59a1cf',
  '#57a0ce',
  '#569fce',
  '#559ecd',
  '#549ecd',
  '#529dcc',
  '#519ccc',
  '#509bcb',
  '#4f9acb',
  '#4d99ca',
  '#4c98ca',
  '#4b97c9',
  '#4a96c9',
  '#4895c8',
  '#4794c8',
  '#4693c7',
  '#4592c7',
  '#4492c6',
  '#4391c6',
  '#4190c5',
  '#408fc4',
  '#3f8ec4',
  '#3e8dc3',
  '#3d8cc3',
  '#3c8bc2',
  '#3b8ac2',
  '#3a89c1',
  '#3988c1',
  '#3787c0',
  '#3686c0',
  '#3585bf',
  '#3484bf',
  '#3383be',
  '#3282bd',
  '#3181bd',
  '#3080bc',
  '#2f7fbc',
  '#2e7ebb',
  '#2d7dbb',
  '#2c7cba',
  '#2b7bb9',
  '#2a7ab9',
  '#2979b8',
  '#2878b8',
  '#2777b7',
  '#2676b6',
  '#2574b6',
  '#2473b5',
  '#2372b4',
  '#2371b4',
  '#2270b3',
  '#216fb3',
  '#206eb2',
  '#1f6db1',
  '#1e6cb0',
  '#1d6bb0',
  '#1c6aaf',
  '#1c69ae',
  '#1b68ae',
  '#1a67ad',
  '#1966ac',
  '#1865ab',
  '#1864aa',
  '#1763aa',
  '#1662a9',
  '#1561a8',
  '#1560a7',
  '#145fa6',
  '#135ea5',
  '#135da4',
  '#125ca4',
  '#115ba3',
  '#115aa2',
  '#1059a1',
  '#1058a0',
  '#0f579f',
  '#0e569e',
  '#0e559d',
  '#0e549c',
  '#0d539a',
  '#0d5299',
  '#0c5198',
  '#0c5097',
  '#0b4f96',
  '#0b4e95',
  '#0b4d93',
  '#0b4c92',
  '#0a4b91',
  '#0a4a90',
  '#0a498e',
  '#0a488d',
  '#09478c',
  '#09468a',
  '#094589',
  '#094487',
  '#094386',
  '#094285',
  '#094183',
  '#084082',
  '#083e80',
  '#083d7f',
  '#083c7d',
  '#083b7c',
  '#083a7a',
  '#083979',
  '#083877',
  '#083776',
  '#083674',
  '#083573',
  '#083471',
  '#083370',
  '#08326e',
  '#08316d',
  '#08306b',
]
//.filter((i,d)=>i%4==0);

const purples = [
  '#0d0887',
  '#100788',
  '#130789',
  '#16078a',
  '#19068c',
  '#1b068d',
  '#1d068e',
  '#20068f',
  '#220690',
  '#240691',
  '#260591',
  '#280592',
  '#2a0593',
  '#2c0594',
  '#2e0595',
  '#2f0596',
  '#310597',
  '#330597',
  '#350498',
  '#370499',
  '#38049a',
  '#3a049a',
  '#3c049b',
  '#3e049c',
  '#3f049c',
  '#41049d',
  '#43039e',
  '#44039e',
  '#46039f',
  '#48039f',
  '#4903a0',
  '#4b03a1',
  '#4c02a1',
  '#4e02a2',
  '#5002a2',
  '#5102a3',
  '#5302a3',
  '#5502a4',
  '#5601a4',
  '#5801a4',
  '#5901a5',
  '#5b01a5',
  '#5c01a6',
  '#5e01a6',
  '#6001a6',
  '#6100a7',
  '#6300a7',
  '#6400a7',
  '#6600a7',
  '#6700a8',
  '#6900a8',
  '#6a00a8',
  '#6c00a8',
  '#6e00a8',
  '#6f00a8',
  '#7100a8',
  '#7201a8',
  '#7401a8',
  '#7501a8',
  '#7701a8',
  '#7801a8',
  '#7a02a8',
  '#7b02a8',
  '#7d03a8',
  '#7e03a8',
  '#8004a8',
  '#8104a7',
  '#8305a7',
  '#8405a7',
  '#8606a6',
  '#8707a6',
  '#8808a6',
  '#8a09a5',
  '#8b0aa5',
  '#8d0ba5',
  '#8e0ca4',
  '#8f0da4',
  '#910ea3',
  '#920fa3',
  '#9410a2',
  '#9511a1',
  '#9613a1',
  '#9814a0',
  '#99159f',
  '#9a169f',
  '#9c179e',
  '#9d189d',
  '#9e199d',
  '#a01a9c',
  '#a11b9b',
  '#a21d9a',
  '#a31e9a',
  '#a51f99',
  '#a62098',
  '#a72197',
  '#a82296',
  '#aa2395',
  '#ab2494',
  '#ac2694',
  '#ad2793',
  '#ae2892',
  '#b02991',
  '#b12a90',
  '#b22b8f',
  '#b32c8e',
  '#b42e8d',
  '#b52f8c',
  '#b6308b',
  '#b7318a',
  '#b83289',
  '#ba3388',
];
// Particles

const blz = ['2f2e41', '3f3d56', '305d7c', '1993bb', '00c6f7'].map (
  d => '#' + d
);

const betterspec = [
  'f94144',
  'f3722c',
  'f8961e',
  'f9c74f',
  '90be6d',
  '43aa8b',
  '577590',
].map (d => '#' + d);

const fluro = ['ffbe0b', 'fb5607', 'ff006e', '8338ec', '3a86ff'].map (
  d => '#' + d
);

const flashycool = ['002626', '0e4749', '95c623', 'e55812', 'efe7da'].map (
  d => '#' + d
);

function newset (colour) {
  const mouse3D = new Mouse3D (camera, sizes);
  // depthOfFieldEffect.target = mouse3D.position3
  const particles = new Particles (renderer, mouse3D, colour);
  scene.add (particles.node);

  return particles;
}

const particleset = [purples, blues, dragon, blz, fluro].map (newset);

/////////////

// Resizing
sizes.addEventListener ('resize', () => {

  camera.aspect = sizes.aspect;
  camera.updateProjectionMatrix();

  renderer.setSize (sizes.width, sizes.height);
  renderer.setPixelRatio (sizes.pixelRatio);

  composer.setSize (sizes.width, sizes.height);

});

// Toggle animation
let isAnimationActive = true;
window.addEventListener ('keyup', event => {
  if (event.key === ' ') {
    isAnimationActive = !isAnimationActive;
  }
});

// Animate
let elapsed = 0;
const clock = new THREE.Clock ();
window.t = clock;
const tick = () => {
  stats.begin();
  // time since last call to getDelta
  const deltaTime = clock.getDelta ();
  // Update mouse3d
  // mouse3D.update ();

  // GPU Compute
  if (isAnimationActive) {
    particleset.forEach (p => p.update (deltaTime));
  }

  // Add a bit of a vertical wave
  const elapsed = clock.elapsedTime / 4;
  const cos = Math.cos (elapsed);
  camera.position.y = camera.position.y - (0.4 + 0.6 * cos);
  camera.lookAt (scene.position);

  
  // Update controls
  controls.update ();
  // Render
  composer.render ();
  stats.end();

  window.requestAnimationFrame (tick);

};
window.c = camera;

tick ();
window.dispatchEvent (event);
window.setInterval (() => {
  
 
  if (isAnimationActive){

    window.dispatchEvent (event);
  
  if (Math.random () > 0.35) {
    return;
  }}
  isAnimationActive = !isAnimationActive;
}, timedelay);
// window.setInterval( ()=> {isAnimationActive = !isAnimationActive},timedelay)


window.setInterval(ramp_scanline,Math.random()*1000+4*timedelay)

function ramp_scanline(){
   const end = 1

   const step = end / 22//250

   
var val= 0;
var n = 0;

var timer = setInterval(() => { 
    n++;
    noiseEffect.blendMode.opacity.value = 0.04 + Math.sin(val);
    val+= step
}, 1);

setTimeout(() => {
 clearInterval(timer);
 console.log(n)
 noiseEffect.blendMode.opacity.value = 0.04 
}, 500);

}


// function ramp_scanline(){
//   const end = 2*Math.PI
//   const npts = 1000
//   const step = end / npts

//   var n=0;
//   for (let i = 0; i < npts; i++) {

//    scanlineEffect.density = 0.75*Math.abs(Math.cos(n));
//    n+=step
//   }
//   console.log(Math.cos(n))
// }